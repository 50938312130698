.blog-comment-list {

	&__item {
		display: flex;
		margin-top: map-get($spacing, medium);
		padding-top: map-get($spacing, medium);
		border-top: 1px solid map-get($brand-colors, gray-light);
	}

	&__avatar {
		height: 60px;
		min-width: 60px;
		width: 60px;
		overflow: hidden;
		position: relative;
		border-radius: 50%;
		border: 1px solid transparent;
		margin-right: map-get($spacing, small);

		img {
			object-fit: cover;
			position: relative;
			height: 100%;
			width: 100%;
		}
	}

	&__comment {
		display: flex;
		flex-direction: column;

		span {
			margin-top: map-get($spacing, tiny);
		}

	}

	&__name {
		text-transform: uppercase;
	}

	&__timestamp {
		color: map-get($brand-colors, gray-cool);
		font-style: italic;
		font-size: $font-size-small;
	}

	&__text {
	}
}
