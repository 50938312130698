/*================================================================================
[Blog Masthead]
*/
.blog-masthead {
	padding-bottom: map-get($spacing, medium);

	&__title {
		position: relative;
		margin: 0;
		padding-bottom: map-get($spacing, medium);

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			border-bottom: 3px solid map-get($brand-colors, red-light);
			width: 5em;
		}
	}

	&__kicker {
		color: map-get($brand-colors, gray-cool);
		font-size: $font-size-small;
		@include pfont-regular;
		margin-bottom: 1em;

		a {
			color: inherit;
			text-decoration: underline;
		}
	}

	&__title-text {
		@include responsive-type($h1-map);
		@include pfont-bold;
		line-height: 1;
		margin: 0;

		.entry-title-primary {
			margin: 0 0 0.25em 0;
			display: block;
		}

		.entry-subtitle {
			@include responsive-type($h3-map);
			@include pfont-light;
			line-height: 1.2;
			display: block;
		}
	}

	&__desc {
		@include responsive-type($h3-map);
		@include pfont-light;
		line-height: 1.2;
	}

}
