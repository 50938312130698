.blog-list-style {
	@media #{$sm-up}{
		display: flex;
	}

	&__img-wrap {
		overflow:hidden;
		@media #{$sm-up}{
			flex: 0 0 32%;
		}

		a {
			display: block;
			overflow:hidden;
			&:hover {
				text-decoration: none;
			}
		}

	}

	&__img {
		height: auto;
		max-width: 100%;
	}

	&__content {
		@media #{$sm-up}{
			flex: 1 1 auto;
			margin-left: map-get($spacing, medium);
		}
	}

	&__header {
		margin-bottom: map-get($spacing, medium);
	}

	&__heading {
		@include responsive-type($h2-map);
		@include pfont-bold;
		color: $text-color;

		.entry-subtitle {
			@include pfont-regular;
			margin-top: map-get($spacing, small);
		}


	}

	&__heading-page {
		@include responsive-type($h1-map);
		color: $text-color;
	}

	&__heading-link {
		color: $text-color;
		text-decoration:none;

		&:after {
			content: '';
			display: block;
			margin: map-get($spacing, medium) 0 map-get($spacing, small) 0;
			width: 20%;
			border-top: 4px solid map-get($brand-colors, red-light);
			-webkit-transition: width $transition-med ease;
			transition: width $transition-med ease;
		}

		&:hover {
			text-decoration:none;
			color: $text-color;
			&:after {
				width: 30%;
			}
		}
	}

	&__meta {
		font-size: $font-size-small;
		color: darken(map-get($brand-colors, gray-light), 12%);
		display: flex;
		margin: map-get($spacing, small) 0;
		align-items: center;
		position: relative;

		@media #{$sm-up}{
			margin: 0;
		}
	}

	&__meta-date {
		flex: 0 0 auto;
		padding-right: map-get($spacing, small);
		border-right: 1px solid darken(map-get($brand-colors, gray-light), 12%);
		margin-right: map-get($spacing, small);

		@media #{$xs-only}{
			display: flex;
			flex-direction: column;
			flex: 0 1 auto;
			align-items: stretch;
		}
	}

	&__meta-categories {
		flex: 1 1 auto;

		a {
			color: darken(map-get($brand-colors, gray-light), 12%);

			&:hover {
				color: map-get($brand-colors, gray-med);
			}
		}

	}
}
