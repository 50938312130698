.blog-author {
	display: flex;
	align-items: flex-start;

	&__avatar {
		height: 50px;
		width: 50px;
		overflow: hidden;
		position: relative;
		border-radius: 50%;
		border: 1px solid transparent;
		margin-right: map-get($spacing, medium);
		flex: 0 0 50px;
		max-width: 50px;

		img {
			object-fit: cover;
			position: relative;
			height: 100%;
			width: 100%;
		}
	}

	&__byline {
		display: flex;
		flex-direction: column;
	}

	&__link {
		color: $text-color;
		text-decoration: none;

		&:hover {
			color: $text-color;
			text-decoration: underline;
		}
	}

	&--centered {
		align-items: center;
	}
}
