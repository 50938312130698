@import "../core/variables";
@import "../core/font-mixins-blog";
@import "../core/mixins/responsive-type";
@import "../lib/bootstrap/mixins";

// Blog modules
@import "../modules/blog-article";
@import "../modules/blog-article-list";
@import "../modules/blog-author";
@import "../modules/blog-comment-list";
@import "../modules/blog-hero";
@import "../modules/blog-list-style";
@import "../modules/blog-masthead";
@import "../modules/blog-nav";

@import "./blog-overview";
@import "./blog-single-post";

.v-blog {
	&__search-form {
		width: 100%;
		margin: 0;
		padding: 0;

		@media #{$sm-up}{
			.blog-nav__container & {
				width: 250px;
			}
		}
	}
}
