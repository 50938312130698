.blog-nav {
	@include list-unstyled;
	padding: 0;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	margin: 0;

	@media #{$xs-only}{
		display: none;

		&.state-visible {
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			width: 100%;
			border-left: 1px solid map-get($brand-colors, gray-light);
			border-right: 1px solid map-get($brand-colors, gray-light);
			border-bottom: 1px solid map-get($brand-colors, gray-light);
			background: map-get($brand-colors, true-white);
			padding: 0;
			margin: 0;
			top: 100%;
			box-shadow: 0 6px 3px -3px rgba(#0A0A0A, 0.2);
			z-index: $zindex-dropdown-high;
		}
	}

	@media #{$sm-up} {
		display: flex;
		margin: 0;
		align-items: center;
		background: transparent;
		transition: all $transition-med ease $transition-med;
	}

	&__container {
		position: relative;
		padding: map-get($spacing, small) 0;

		@media #{$sm-up}{
			padding: 0;
			display: flex;
		}
	}

	&__mobile-wrap {
		position: relative;
	}


	/*------- [Navigation list item] -------*/
	&__item {

		@media #{$xs-only} {
			position: relative;
		}

		@media #{$sm-only} {
			display: flex;
			flex: 0 0 auto;
			align-self: stretch;
		}

		@media #{$md-up}{
			flex: 0 0 auto;
			align-self: stretch;
		}

		&.current-menu-item {
			.blog-nav__link {
				@media #{$xs-only}{
					background: map-get($brand-colors, gray-light);
				}
			}
		}
	}

	/*------- [Navigation trigger link] -------*/
	&__link {
		display: block;
		padding: map-get($spacing, medium) map-get($spacing, medium);
		border-bottom: 2px solid transparent;
		transition: background-color $transition-med ease, border-color $transition-med ease;
		line-height: 1.1;
		font-size: 0.875em;
		font-weight: bold;


		&, &:hover, &:active, &:focus, li.current-menu-item & {
			color: $text-color;
			text-decoration: none;
		}

		@media #{$xs-only} {
			position: relative;
			font-size: 0.825em;
			padding: map-get($spacing, small) map-get($spacing, medium) map-get($spacing, small) map-get($spacing, medium);
		}

		@media #{$sm-only} {
			font-size: 0.75em;
			padding: 18px;
		}

		/*------- [Desktop treatment: hover block with underline] -------*/
		@media #{$sm-up} {
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;
		}

		@media #{$md-up} {
			padding: map-get($spacing, medium) map-get($spacing, medium);
		}

		/*------- [Hover / selected state] -------*/
		&:hover, &:active, &:focus, li.current-menu-item & {
			position: relative;
			background: map-get($brand-colors, gray-light);

			&:after {
				opacity: 1;
			}
		}
	}

	&__trigger {
		position: relative;
		display: block;
		background: white;
		border: 1px solid map-get($brand-colors, gray-light);
		padding: map-get($spacing, small) map-get($spacing, huge) map-get($spacing, small) map-get($spacing, medium);
		color: $text-color;
		border-radius: 2px;
		text-transform: uppercase;
		@include pfont-bold;
		font-size: 0.875em;
		margin-bottom: map-get($spacing, small);

		&:hover {
			color: $text-color;
			text-decoration: none;
		}

		&:before {
			content: "\e911";
			font-family: icomoon;
			color: map-get($brand-colors, red-light);
			font-size: 1.2em;
			display: inline-block;
			position: absolute;
			right: map-get($spacing, medium);
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
