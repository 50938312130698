.v-blog-single-post {
	padding-bottom: map-get($spacing, medium);

	.icons-list {
		@media #{$md-up}{
			margin-top: 15px;
		}
	}
	.icons-block {
		&__icon {
			font-size: $font-size-h4;
		}
		&__text {
			font-size: $font-size-small;
		}
	}

	&__main {
		img {
			max-width: 100%;
		}
	}

	&__ad {
		img {
			display: block;
			margin: 0 auto map-get($spacing, large);
			max-height: 300px;
			width: auto !important;
		}
	}
}
