/*================================================================================
[Blog Hero]
*/
.blog-hero {
	position: relative;
	height: 90vh;
	min-height: 200px;
	max-height: 260px;
	overflow: hidden;
	margin-bottom: map-get($spacing, medium);

	@media #{$sm-only}{
		max-height: 470px;
	}

	@media #{$md-up} {
		max-height: 650px;
	}

	/* The text is a light color in the example and the image is light so adding this gradient to make it more readable*/

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		z-index: 1;
		display: block;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.40) 30%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
	}

	article & {
		&:after {
			display: none;
		}
	}

	&__bg {
		position: relative;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__title {
		position: absolute;
		bottom: map-get($spacing, medium);
		left: map-get($spacing, medium);
		right: map-get($spacing, medium);
		margin: 0;
		padding-bottom: map-get($spacing, medium);

		z-index: 2;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			border-bottom: 3px solid map-get($brand-colors, red-light);
			width: 5em;
			transition: all $transition-med ease;
		}

		&:hover {
			&:after {
				width: 8em;
			}
		}
	}

	&__kicker {
		color: darken(map-get($brand-colors, gray-light), 10%);
		font-size: $font-size-small;
		@include pfont-regular;
		margin-bottom: 1em;

		a {
			color: inherit;
			text-decoration: underline;
		}
	}

	&__title-text {
		color: map-get($brand-colors, white);
		@include responsive-type($h1-map);
		@include pfont-bold;
		line-height: 1;
		margin-bottom: 0.25em;
		text-decoration: none;

		&:hover {
			text-decoration: none;
			color: map-get($brand-colors, white);
		}
	}

	&__desc {
		color: map-get($brand-colors, white);
		@include responsive-type($h3-map);
		@include pfont-light;
		line-height: 1.2;
	}
}
