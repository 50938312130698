.blog-article-list {
	margin-bottom: map-get($spacing, medium);
	padding-left: 0;
	list-style: none;

	&__item {
		padding-bottom: map-get($spacing, medium);
		border-bottom: 1px solid map-get($brand-colors, gray-light);

		&:first-child {
			.blog-article {
				padding-top: 0;
			}
		}
	}
}

.pager {
	&__cta {
		i {
			line-height: 1.5em;
		}
	}
	&__link-text {
		text-decoration: none;
		line-height: 27px;
	}
}
