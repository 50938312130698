.v-blog-overview {
	padding-bottom: map-get($spacing, medium);

	&__search-form {
		display: flex;
		align-items: center;

		form {
			margin-top: -12px;
			padding-top: 12px;
			padding-bottom: 12px;

			@media #{$sm-up}{
				flex-basis: 33.33%;
				margin-top: -46px;
				margin-left: auto;
				padding-top: 0;
				padding-bottom: 0;
			}

			@media #{$md-up}{
				margin-top: -53px;
			}

			@media #{$lg-up}{
				margin-top: -55px;
			}
		}
	}
}

